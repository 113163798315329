@import "~bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&display=swap");

body {
  overflow-x: hidden;
}

main {
  @media (max-width: 375px) {
    // overflow-x: hidden;
    width: 100%;
  }
}

a {
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

a,
p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
li {
  letter-spacing: -0.64px !important;
}

.hr {
  background: #d9d9d9;
  border: 1px solid #2c2c2c;
  margin: 0 50px;

  @media (max-width: 767px) {
    margin: 0 10px;
  }
}

.line {
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);

  @include media-breakpoint-down(md) {
    border-bottom: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.slick-track {
  display: flex !important;
  align-items: center !important;
  padding-top: 15px;
}

footer {
  background: #111818;
  color: #ffffff;
  padding: 7.5rem 0 7rem;
  .web-footerlink {
    a {
      // font-size: 12px;
      font-size: 14px !important;
    }
    @include media-breakpoint-down(sm) {
      a {
        font-size: 12px !important;
      }
    }
  }

  @include media-breakpoint-down(md) {
    padding: 64px 0 0;
    padding-top: 70px !important;
  }

  .container {
    @include media-breakpoint-down(md) {
      padding-right: 19.5px;
      padding-left: 19.5px;
    }
  }

  h2 {
    color: #ffffff !important;
    margin: 1.44rem 0;

    @include media-breakpoint-down(md) {
      font-weight: 500;
      font-size: 28px;
    }
  }

  h3 {
    font-weight: 500;
    font-size: 1.375rem;

    @include media-breakpoint-down(md) {
      font-weight: 500;
      font-size: 20.792px;
    }
  }

  .mb-sub {
    margin-bottom: 1.31rem;
  }

  input {
    width: 100%;
    border: none;
    border-bottom: 2px solid #ffffff;
    background: transparent;
    padding: 10px 0;
    color: #919191;
    font-weight: 400;
    outline: none;
    font-size: 18px;

    @media (max-width: 1360px) {
      font-size: 17px;
    }
  }

  .hr {
    // margin-top: 4rem; removed bcz of new refer btnlink
    margin-bottom: 4rem;
    margin-left: 0;
    margin-right: 0;

    @include media-breakpoint-down(md) {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }

  .company-icon {
    width: 23px;
    height: 31px;
    background: #3ebfc1;
    border-radius: 20px;
  }

  a {
    color: #ffffff;
    text-decoration: none;
    font-size: 1rem;
    color: #fcfcfc;
    // font-family: "Inter Tight";
    font-size: 16px;
    font-style: normal;
    font-weight: 440;
    line-height: 160%; /* 25.6px */
    letter-spacing: 0px !important;
    &:hover {
      color: #3ebfc1;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      // line-height: 24px;
      margin-bottom: 16px;

      @include media-breakpoint-down(md) {
        margin-bottom: 8px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .link-mob {
    @include media-breakpoint-down(md) {
      margin-top: 31.82px;
    }
  }

  .mob-subscribe {
    @include media-breakpoint-down(md) {
      margin-top: 24px;
    }
  }

  .cursor-pointer {
    cursor: pointer !important;
  }

  .footer-text {
    white-space: nowrap;
  }

  @media (min-width: 500px) {
    .terms-t {
      display: none !important;
    }
  }

  @media (max-width: 500px) {
    .terms-v {
      display: none !important;
    }
  }

  .mob-privacy {
    & > div {
      justify-content: flex-start;
    }
    a {
      font-weight: 500;
      font-size: 14px;
      color: #8f8f8f;

      @include media-breakpoint-down(sm) {
        font-size: 10px;
        position: relative;
        padding-right: 15px;

        &::after {
          content: "";
          width: 1px;
          height: 13px;
          background: #fff;
          opacity: 0.4;
          position: absolute;
          right: 6px;
        }
        &:last-child {
          padding-right: 0;
          &::after {
            display: none;
          }
        }
      }
    }
  }

  .social-mob {
    margin: 29.21px 0;
    @include media-breakpoint-down(md) {
      margin-bottom: 66px !important;
    }
    a {
      display: block;
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .copyright-mob {
    font-weight: 500;
    font-size: 14px;
    color: #8f8f8f;
  }

  .links-heading {
    margin-bottom: 24px;
    color: #fcfcfc;
    // font-family: "Inter Tight";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 28px */
    opacity: 0.7 !important;
    letter-spacing: 0px !important;
  }

  .h5 {
    @media (max-width: 1360px) {
      font-size: 14px;
    }
  }

  .social {
    a {
      @media (max-width: 1360px) {
        font-size: 24px;
      }
    }
  }

  .copyright {
    @media (max-width: 1360px) {
      font-size: 11.33px;
    }
  }

  .links {
    h3 {
      @include media-breakpoint-down(md) {
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 12px;
      }
      @include media-breakpoint-down(sm) {
        font-weight: 500;
        font-size: 12px;
        margin-bottom: 12px;
      }
    }

    a {
      @include media-breakpoint-down(xxl) {
        font-size: 12px;
      }

      @include media-breakpoint-down(md) {
        font-weight: 400;
        font-size: 14px;
        // color: #a5a5a5;
      }
      @include media-breakpoint-down(sm) {
        font-size: 12px;
      }
    }
  }
}

.footer-banner {
  padding: 7.5rem 0 7rem;
  // padding-top: 7.5rem !important;

  @include media-breakpoint-down(md) {
    padding: 70px 0 0 !important;
    padding-top: 70px !important;
  }

  h2 {
    color: #ffffff;
    margin: 12px 0px 24px 0px;

    @include media-breakpoint-down(md) {
      font-weight: 500;
      font-size: 28px;
    }
  }

  h3 {
    font-weight: 600;
    font-size: 20px;
    opacity: 100%;
    color: #fcfcfc;
    // font-family: "Inter Tight";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 28px */
    letter-spacing: 0 !important;
    @include media-breakpoint-down(md) {
      font-weight: 500;
      font-size: 18px;
      opacity: 1;
    }
  }

  .mb-sub {
    margin-bottom: 1.31rem;
  }
}

header {
  .navbar-light {
    background-color: #ffffff !important;
  }

  .navbar-transparent {
    background-color: transparent !important;
  }

  .header-color-white {
    color: #ffffff !important;
    @media (max-width: 991px) {
      color: black !important;
    }
  }

  .drop-toggle-icon > a:first-child {
    color: #ffffff !important;
    @media (max-width: 991px) {
      color: black !important;
    }
  }

  .bg-home-blue {
    background-color: rgba(0, 78, 113, 0.1) !important;
  }

  .fixed-border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  }

  .fixed-top {
    animation-name: fixedAnim;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }

  .navbar {
    padding: 0;
  }

  .navbar-expand-lg {
    img.logo {
      width: 138.6px;
      height: 33px;

      @include media-breakpoint-down(xxl) {
        width: 98.18px;
        height: 30.38px;
      }
    }
  }

  .menu-container {
    padding: 10px 48px;
    // background-color: rgba(255, 255, 255, 0.073); //CHECK

    @media (min-width: 320px) and (max-width: 399px) {
      padding-left: 5px;
      padding-right: 5px;
    }

    @include media-breakpoint-down(xxl) {
      padding: 10px 10px;
    }

    @include media-breakpoint-down(md) {
      padding: 20px;
    }

    .navbar-white > span {
      background-image: url(../../public/assets/icons/overflow-white.svg) !important;
    }

    .navbar-toggler {
      border-radius: 0;
      padding: 0;
      border: none;
      box-shadow: none;
      margin-bottom: 2px;

      .navbar-toggler-icon {
        background-image: url(../../public/assets/icons/overflow.svg);
        background-size: 22px 16px;
        width: 23px;
        height: 18px;
      }
    }
  }

  .nav-link {
    font-weight: 500;
    font-size: 1rem;
    line-height: 24px;
    color: #080a0e;
    letter-spacing: -0.02em;
    margin-right: 2.5rem;
  }

  .navbar-brand {
    margin-right: 50px;

    @include media-breakpoint-down(md) {
      display: contents;
    }
  }

  .connect {
    padding: 16px 24px;
    background: #080a0e;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-decoration: none;
    position: relative;
    transition: all 250ms;
    z-index: 1;

    @media (max-width: 1360px) {
      font-size: 11.33px;
      padding: 11.3333px 17px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 0;
      background-color: #3ebfc1;
      z-index: -1;
      transition: all 250ms;
    }

    &:hover {
      color: #000000;

      &::after {
        width: 100%;
      }
    }
  }

  .standbtn {
    padding: 16px 24px;
    background: transparent;
    color: #071717;
    border: 1px solid #040c0c;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-decoration: none;
    position: relative;
    transition: all 250ms;
    z-index: 1;

    @media (max-width: 1360px) {
      font-size: 11.33px;
      padding: 11.3333px 17px;
    }

    &:hover {
      color: #000000;

      &::after {
        width: 100%;
      }
    }
  }

  .landing-connect {
    background: #ffffff;
    color: #000;
  }

  .navbar-nav .nav-item:hover .dropdown-menu {
    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  .dropdown-menu[data-bs-popper] {
    top: 71%;
    margin: 0;

    @media (max-width: 1360px) {
      top: 70%;
    }
  }

  .dropdown-menu {
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
    padding-top: 20px;
    margin-top: 20px;
    border: none;
    background: transparent;

    a {
      color: #ffffff;

      &:hover {
        color: #3ebfc1;
        background: transparent;
      }
    }
  }

  .nav-item {
    position: unset;
  }

  .nav-item:hover {
    .nav-link {
      font-weight: 500;
      color: #00415e;

      &::after {
        transform: rotate(180deg);
      }
    }

    .dropdown-menu {
      opacity: 1;
      visibility: visible;
    }
  }

  .nav-item {
    .nav-link {
      @include media-breakpoint-down(lg) {
        &::after {
          transform: rotate(360deg) !important;
        }
      }
    }

    .show {
      @include media-breakpoint-down(lg) {
        &::after {
          transform: rotate(180deg) !important;
        }
      }
    }
  }

  .menu-dropdown {
    background: #ffffff;
    border-top: 1px solid #f0f2f2;
    box-shadow: 0px 143px 86px rgba(0, 0, 0, 0.01),
      0px 64px 64px rgba(0, 0, 0, 0.02), 0px 16px 35px rgba(0, 0, 0, 0.03),
      0px 0px 0px rgba(0, 0, 0, 0.03);

    @media (max-width: 991px) {
      box-shadow: none;
    }

    .nav-link {
      margin-right: 0;
    }

    .nav-pills {
      padding: 35px 0;
      border-right: 1px solid #f0f2f2;
      height: 100%;

      @media (max-width: 991px) {
        border-right: none;
        padding: 35px 0 0;
      }
    }

    .navbar-nav {
      .nav-item {
        a {
          font-weight: 400;
          font-size: 18px;
          color: #545959;
          padding: 14px 40px;

          @include media-breakpoint-down(xxl) {
            font-size: 16px;
          }
        }

        .active {
          font-weight: 700;
          color: #00415e;
          background: #f0f2f2;
          border-radius: 0;
        }
      }
    }

    .tab-content {
      padding: 35px 0;

      @media (max-width: 991px) {
        padding: 0 0 35px;
      }

      h5 {
        font-weight: 600;
        font-size: 20px;
        color: #00415e;
        display: flex;
        align-items: center;

        a {
          font-weight: 600;
          // font-size: 20px;
          font-size: 18px;
          color: #00415e;
          text-decoration: none;
          white-space: nowrap;

          img {
            width: 20px;
            margin-left: 5px;
          }

          @include media-breakpoint-down(xxl) {
            font-size: 16px;
            font-size: 15px;
          }

          &:hover {
            text-decoration: none;
          }
        }
      }

      p {
        font-weight: 400;
        font-size: 14px;
        color: #545959;
        margin-bottom: 30px;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        @media (max-width: 991px) {
          margin-bottom: 30px;
        }

        li {
          margin-bottom: 30px;

          &:last-child {
            margin-bottom: 0;
          }

          a {
            font-weight: 500;
            font-size: 16px;
            color: #040d0d;
            text-decoration: none;

            @include media-breakpoint-down(xxl) {
              font-size: 14px;
            }

            &:hover {
              color: #3ebfc1;
            }
          }
        }
      }
    }
  }
}

@keyframes fixedAnim {
  from {
    top: -50px;
  }

  to {
    top: 0;
  }
}

.accordion {
  .accordion-item {
    background: transparent;
    border: none;
    border-bottom: 1px solid #ffffff;

    &:last-of-type {
      border-radius: 0;
    }

    .accordion-header {
      padding: 25px 0;

      @include media-breakpoint-down(md) {
        padding: 17.25px 0;
      }

      .accordion-button {
        font-weight: 500;
        font-size: 39px;
        color: #ffffff;
        background: transparent;
        border: none;
        box-shadow: none;
        padding: 0;

        @include media-breakpoint-down(xxl) {
          font-size: 26px;
        }

        @include media-breakpoint-down(md) {
          font-weight: 500;
          font-size: 25px;
        }

        &::after {
          background-image: unset;
        }

        &:not(.collapsed) {
          &::after {
            background-image: unset;
          }
        }
      }
    }

    .accordion-body {
      padding: 0 0 35px;
      color: rgba(255, 255, 255, 0.88);

      @include media-breakpoint-down(md) {
        padding: 0 0 29.33px;
      }

      p {
        @include media-breakpoint-down(xxl) {
          font-size: 12px;
        }

        @include media-breakpoint-down(md) {
          font-weight: 400;
          font-size: 12px;
        }
      }
    }
  }
}

.top-bottom {
  padding: 100px 0;

  @include media-breakpoint-down(md) {
    padding: 103.4px 0 100px;
  }

  h2 {
    @include media-breakpoint-down(md) {
      font-weight: 500;
      font-size: 25px;
      color: #00415e !important;
      margin-bottom: 24px !important;
    }
  }
}

.color-dark {
  color: #040d0d !important;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mt-100 {
  margin-top: 100px;

  @media (min-width: 768px) {
    margin-top: 0;
  }
}

.subheading {
  font-weight: 400;
  font-size: 26px;
  line-height: 31px;
  color: #545959;
}

.heading {
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.04em;
  color: #00415e;
  font-size: 3.0625rem;

  @media (max-width: 821px) {
    font-size: 2.0625rem;
  }

  @include media-breakpoint-down(md) {
    font-size: 25px;
  }
}

.heading-new {
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.04em;
  color: #00415e;
  font-size: 3.0625rem;

  @include media-breakpoint-down(md) {
    font-size: 25px;
  }
}

.normal-text {
  font-weight: 400;
  font-size: 1rem;
  color: #545959;

  @include media-breakpoint-down(md) {
    font-size: 12px;
    // white-space: nowrap;
  }
  // @include media-breakpoint-down(xxl) {
  //   font-size: 12px;
  //   // white-space: nowrap;
  // }
}

.blue-link {
  background: #3ebfc1;
  padding: 15px 20px;

  &:hover {
    background: #ffffff;

    img {
      filter: invert(65%) sepia(29%) saturate(870%) hue-rotate(132deg)
        brightness(97%) contrast(86%);
    }
  }

  @include media-breakpoint-up(xl) {
    padding: 20px 22px 20px 21px;
  }
}

.white-link {
  background: #ffffff;
  padding: 15px 20px;

  &:hover {
    background: #3ebfc1;

    img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
        saturate(1845%) hue-rotate(316deg) brightness(96%) contrast(104%);
    }
  }

  @include media-breakpoint-down(md) {
    padding: 0;
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.form-input {
  border: none;
  border-bottom: 2px solid #080a0e;
  font-weight: 700;
  font-size: 17px;
  color: #080a0e;
  padding: 15px 0;
  outline: none;
  width: 100%;

  @media (max-width: 1360px) {
    font-size: 12px;
  }
}

.form-button {
  width: 100%;
  background: #080a0e;
  padding: 10px 0;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  border: 1px solid #080a0e;

  @media (max-width: 1360px) {
    font-size: 12px;
  }
}

.flex-1 {
  flex: 1;
}

.banner-opacity {
  animation: smooth-appear 0.5s ease forwards;
}

.banner-expand-silent {
  animation: smooth-appear 0.5s ease forwards !important;
}

@keyframes smooth-appear {
  to {
    opacity: 1;
  }
}

.banner-active {
  white-space: nowrap;
  font-size: 14px;
  color: #00415e;

  @media (max-width: 1360px) {
    font-size: 12px;
  }
}

.banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #eaeded;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 27px 0;
  z-index: 999;
  opacity: 0;

  @include media-breakpoint-down(md) {
    padding: 17.5px 16px;
    position: relative;
  }

  h5 {
    font-weight: 500;
    font-size: 17px;
    margin-bottom: 0;

    @include media-breakpoint-down(md) {
      font-weight: 500;
      font-size: 16px;
      color: #000000;
      margin-bottom: 16px;
    }
  }

  button {
    flex: 1;
    font-weight: 400;
    font-size: 14px;
    padding: 5px 0;
    transition: all 0.2s ease-in;

    @media (max-width: 1360px) {
      font-size: 12px;
    }

    &:first-child {
      background: transparent;
      border: 1px solid #080a0e;
      color: #080a0e;
      white-space: nowrap;

      &:hover {
        background: #fcfcfc;
      }

      @include media-breakpoint-down(md) {
        color: #00415e;
        border: 1px solid #00415e;
        margin-right: 8px !important;
      }
    }

    &:nth-child(2) {
      background: transparent;
      border: 1px solid #080a0e;
      color: #080a0e;

      &:hover {
        background: #fcfcfc;
      }

      @include media-breakpoint-down(md) {
        color: #00415e;
        border: 1px solid #00415e;
        margin-right: 8px !important;
      }
    }

    &:last-child {
      border: 1px solid #080a0e;
      color: white;
      background-color: #004e71;

      &:hover {
        background: #004e71;
      }

      @include media-breakpoint-down(md) {
        margin-left: 8px !important;
      }
    }

    @include media-breakpoint-down(md) {
      padding: 12px 5px;
      font-weight: 600;
      font-size: 16px;
    }
  }

  .cookiebanner-last-btn {
    border: 1px solid #080a0e !important;

    &:hover {
      background: #004e71 !important;
    }

    @include media-breakpoint-down(md) {
      margin-left: 8px !important;
    }
  }

  .cookiebanner-btn {
    flex: 1;
    font-weight: 400;
    font-size: 14px;
    padding: 5px 0;
    transition: all 0.2s ease-in;

    @media (max-width: 1360px) {
      font-size: 12px;
    }

    background: transparent !important;
    border: 1px solid #080a0e !important;
    color: #080a0e !important;

    &:hover {
      background: #fcfcfc !important;
    }

    @include media-breakpoint-down(md) {
      color: #00415e;
      border: 1px solid #00415e;
      margin-right: 8px !important;
    }

    @include media-breakpoint-down(md) {
      padding: 12px 5px;
      font-weight: 600;
      font-size: 16px;
    }
  }

  .form-button {
    &:hover {
      border: 1px solid #efeff0;
    }
  }
}

.button-transformation {
  @include media-breakpoint-down(md) {
    margin-top: 16px;
  }

  .disabled {
    border: 1px solid #cccccc;
    background: #cccccc;
    opacity: 1;

    img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(93%) saturate(29%)
        hue-rotate(118deg) brightness(106%) contrast(108%);
    }
  }
}

.prev-button {
  background: none;
  border: 1px solid #00415e;
  border-radius: 50%;
  width: 3.25rem;
  height: 3.25rem;

  @include media-breakpoint-down(md) {
    padding: 7px 11px;
    margin-right: 16px;
    width: 2.5rem;
    height: 2.5rem;
  }

  img {
    transform: rotate(180deg);
  }

  &:hover {
    background: #00415e;
    border-color: #00415e;

    img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
        saturate(1845%) hue-rotate(316deg) brightness(96%) contrast(104%);
    }
  }

  &:active,
  &:focus-visible {
    background-color: #00415e !important;
    border-color: #00415e !important;
    box-shadow: none;

    img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
        saturate(1845%) hue-rotate(316deg) brightness(96%) contrast(104%);
    }
  }

  &:disabled {
    border: 1px solid #cccccc;
    background: #cccccc;
    opacity: 1;

    img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(93%) saturate(29%)
        hue-rotate(118deg) brightness(106%) contrast(108%);
    }
  }
}

.next-button {
  background: none;
  border: 1px solid #00415e;
  border-radius: 50%;
  width: 3.25rem;
  height: 3.25rem;

  @include media-breakpoint-down(md) {
    padding: 7px 11px;
    width: 2.5rem;
    height: 2.5rem;
  }

  &:hover {
    background: #00415e;
    border-color: #00415e;

    img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
        saturate(1845%) hue-rotate(316deg) brightness(96%) contrast(104%);
    }
  }

  &:active,
  &:focus-visible {
    background-color: #00415e !important;
    border-color: #00415e !important;
    box-shadow: none;

    img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
        saturate(1845%) hue-rotate(316deg) brightness(96%) contrast(104%);
    }
  }

  &:disabled {
    border: 1px solid #cccccc;
    background: #cccccc;
    opacity: 1;

    img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(93%) saturate(29%)
        hue-rotate(118deg) brightness(106%) contrast(108%);
    }
  }
}

.landing-contact {
  background-color: #f0f2f2;
  margin: -101px 0 0;

  .contact {
    @media (max-width: 399px) {
      margin-left: 10px;
      margin-right: 10px;
    }

    @media (min-width: 400px) and (max-width: 599px) {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0;
    }

    @media (min-width: 600px) and (max-width: 1600px) {
      margin-left: 50px;
      margin-right: 50px;
    }
  }
}

.why-choose-heading {
  @media (min-width: 992px) and (max-width: 1200px) {
    margin-bottom: -10rem !important;
  }
}

.contact {
  margin: 10rem 0 0;
  box-shadow: 0px 739px 296px rgba(0, 0, 0, 0.01),
    0px 416px 250px rgba(0, 0, 0, 0.04), 0px 185px 185px rgba(0, 0, 0, 0.07),
    0px 46px 102px rgba(0, 0, 0, 0.08), 0px 0px 0px rgba(0, 0, 0, 0.08);
  margin-bottom: -50px;
  background: #ffffff;
  margin-left: 100px;
  margin-right: 100px;
  position: relative;

  @media (max-width: 399px) {
    margin-left: 10px;
    margin-right: 10px;
  }

  @media (min-width: 400px) and (max-width: 599px) {
    margin-left: 20px;
    margin-right: 20px;
  }

  @media (min-width: 600px) and (max-width: 1366px) {
    margin-left: 50px;
    margin-right: 50px;
  }

  .container {
    padding-top: 50px;
    padding-bottom: 100px;

    @include media-breakpoint-down(md) {
      padding: 64px 16px;
    }

    h3 {
      font-weight: 700;
      font-size: 48px;
      color: #1e1e1e;

      @media (max-width: 1360px) {
        font-size: 34px;
      }

      @include media-breakpoint-down(md) {
        font-weight: 600 !important;
        font-size: 24px !important;
        color: #040d0d !important;
        margin-bottom: 23px !important;
      }
    }

    p {
      font-weight: 400;
      font-size: 16px;
      color: #080a0e;
      margin: 30px 0 0;

      @media (max-width: 1360px) {
        font-size: 14px;
      }

      @include media-breakpoint-down(md) {
        margin-top: 23px !important;
        font-weight: 400;
        font-size: 12px;
        color: #040d0d;
      }
    }

    form {
      p {
        margin-bottom: 2rem;
        margin-top: 0;
      }
    }

    .h6 {
      font-weight: 700;
      font-size: 24px;
      color: #080a0e;

      @media (max-width: 1360px) {
        font-size: 17px;
      }

      @include media-breakpoint-down(md) {
        margin-top: 23px !important;
        margin-bottom: 34px !important;
        font-weight: 700;
        font-size: 14px;
      }
    }
  }
}

.landing-contact-fixed {
  padding: 7.5rem 0;
  background: #f0f2f2;

  @include media-breakpoint-down(md) {
    padding: 64px 0;
  }

  .container {
    @include media-breakpoint-down(md) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  h3 {
    color: #00415e;

    @include media-breakpoint-down(md) {
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 23px !important;
    }
  }

  p {
    color: #040d0d;
    font-weight: 400;
    font-size: 1rem;
    margin-top: 2rem;
  }

  .h6 {
    font-weight: 500;
    font-size: 1.375rem;
    color: #080a0e;
  }

  .form-input {
    background: transparent;
  }

  button {
    background: #00415e;
    border: 1px solid #00415e;
  }

  img {
    max-height: 32.1875rem;
  }
}

.contact-fixed {
  // padding: 7.5rem 0;
  padding: 10rem 0;
  background: #f0f2f2;

  @include media-breakpoint-down(lg) {
    padding: 64px 0;
  }

  .container {
    @include media-breakpoint-down(md) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  h3 {
    color: #00415e;

    @include media-breakpoint-down(md) {
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 23px !important;
    }
  }

  p {
    color: #040d0d;
    font-weight: 400;
    font-size: 1rem;
    margin-top: 2rem;
  }

  .h6 {
    font-weight: 500;
    font-size: 1.375rem;
    color: #080a0e;
  }

  .form-input {
    background: transparent;
  }

  button {
    background: #00415e;
    border: 1px solid #00415e;
  }

  img {
    max-height: 32.1875rem;
  }
}

.home-banner {
  button {
    &:last-child {
      background: #00415e;
      border: 1px solid #00415e;

      @include media-breakpoint-down(md) {
        font-weight: 700;
      }
    }
  }
}

.casestudy-box {
  position: relative;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center;

  @include media-breakpoint-down(md) {
    height: 433px;
  }

  @include media-breakpoint-up(md) {
    height: 427.13px;
  }

  @include media-breakpoint-up(xxl) {
    height: 520px;
  }

  .text-content {
    background: linear-gradient(
      1.39deg,
      rgba(10, 60, 71, 0.8) 1.41%,
      rgba(5, 29, 34, 0.504) 27.34%,
      rgba(0, 0, 0, 0) 99.04%
    );
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0 50px 30px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .h3 {
      font-weight: 500;
      font-size: 39px;
      color: #ffffff;
      opacity: 1;
    }

    h4 {
      font-weight: 500;
      font-size: 31px;
      color: #ffffff;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
      opacity: 0.8;
      padding-right: 5px;
    }
  }
}

.without-over {
  margin-bottom: 5.875rem;
}

.resources {
  background: #f0f2f2;
  padding: 7.5rem 0 4.5rem;

  @include media-breakpoint-down(md) {
    padding: 64px 0 64px;
  }

  .container {
    @include media-breakpoint-down(md) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  h2 {
    @include media-breakpoint-down(md) {
      margin-bottom: 40px !important;
    }
  }

  .h5 {
    font-weight: 500;
    font-size: 1.375rem;
    color: #040d0d;
    margin: 1.62rem 0 1rem;

    @include media-breakpoint-down(md) {
      margin: 0 0 16px 0;
      font-weight: 500;
      font-size: 18px;
    }
  }

  .mt-16 {
    margin-top: 16px;
  }

  .white-link {
    @include media-breakpoint-down(md) {
      img {
        width: 8.4px;
        height: 8.4px;
      }
    }
  }

  .resource-box {
    @include media-breakpoint-down(md) {
      margin-bottom: 48px;
    }
  }

  .new-box {
    @include media-breakpoint-down(md) {
      margin-top: 16px;
    }
  }

  .normal-text {
    @include media-breakpoint-down(md) {
      margin-bottom: 0;
    }
  }

  .img-res-link {
    position: relative;

    .white-link {
      position: absolute;
      right: 1.12rem;
      bottom: 1.56rem;
    }
  }

  .bg-img-container {
    height: 350px !important;
    overflow: hidden;

    // @include media-breakpoint-down(sm) {
    //   height: 225px !important;
    // }
    @media (max-width: 575px) {
      height: 250px !important;
    }

    @media (max-width: 1400px) and (min-width: 576px) {
      height: 300px !important;
    }

    // @include media-breakpoint-down(sm) & media-breakpoint-up(lg){
    //   height: 275px !important;
    // }
    .image-resource-bg {
      background-repeat: no-repeat !important;
      background-size: cover !important;
      background-position: center !important;
      background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    }
  }
}

.error_404 {
  padding: 150px 0;
  display: flex;
  justify-content: center;
  text-align: center;

  @include media-breakpoint-down(md) {
    padding: 111px 0;
  }

  h1 {
    font-weight: 400;
    font-size: 42px;
    color: #00415e;
    margin-top: 30px;

    @include media-breakpoint-down(xxl) {
      font-size: 30px;
    }

    @include media-breakpoint-down(md) {
      font-weight: 500;
      font-size: 25px;
    }
  }

  p {
    font-weight: 400;
    font-size: 24px;
    color: #545959;

    @include media-breakpoint-down(xxl) {
      font-size: 17px;
    }

    @include media-breakpoint-down(md) {
      font-weight: 400;
      font-size: 12px;
    }
  }

  a {
    display: block;
    font-weight: 700;
    font-size: 24px;
    color: #000000;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(xxl) {
      font-size: 17px;
    }

    @include media-breakpoint-down(md) {
      font-weight: 400;
      font-size: 16px;
    }

    img {
      margin-left: 10px;
      filter: brightness(0) saturate(100%) invert(0%) sepia(100%) saturate(0%)
        hue-rotate(280deg) brightness(106%) contrast(97%);
    }
  }
}

.why-choose {
  padding: 7.5rem 0;

  @media (max-width: 992px) {
    padding-bottom: 100px;
  }

  @include media-breakpoint-down(md) {
    padding: 64px 0;
  }

  .container {
    @include media-breakpoint-down(md) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  h2.heading {
    @include media-breakpoint-down(md) {
      margin-bottom: 16px !important;
    }
  }

  p.normal-text {
    @include media-breakpoint-down(md) {
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 40px !important;
    }
  }

  img {
    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
    }
  }

  .single {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
      display: block;
    }

    h4 {
      font-weight: 500;
      font-size: 1.375rem;
      color: #040d0d;
      flex: 0.4;
      margin-bottom: 0;

      @media (max-width: 1360px) {
        font-size: 18px;
      }

      @include media-breakpoint-down(md) {
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 16px;
      }
    }

    p {
      flex: 1;
      margin-bottom: 0;
      margin-left: 100px;

      @include media-breakpoint-down(md) {
        margin-bottom: 0 !important;
        margin-left: 0;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }

  .line {
    margin: 2.19rem 0;

    @include media-breakpoint-down(md) {
      border-bottom: 1px solid #d0d0d0 !important;
      margin: 32px 0 !important;
    }
  }
}

.services-contact {
  .contact {
    margin: 0 !important;
    background: #f0f2f2;
  }
}

.insight-contact {
  .contact {
    background: #ffffff;
  }
}

.hear {
  padding: 10rem 0;
  background: #f0f2f2;
  overflow: hidden;

  @include media-breakpoint-down(md) {
    padding: 64px 0;
  }

  .container {
    @include media-breakpoint-down(md) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  h2.heading {
    @include media-breakpoint-down(md) {
      color: #00415e;
      margin-bottom: 20px !important;
    }
  }

  .left-side {
    p {
      font-weight: 500;
      font-size: 1.25rem;
      color: #040d0d;

      @include media-breakpoint-down(md) {
        font-size: 16px;
        margin-top: 24px;
        margin-bottom: 0;
      }
    }
  }

  .img-second {
    margin: 24px 0 40px;
  }

  p.normal-text {
    @include media-breakpoint-down(md) {
      font-size: 14px;
      margin-bottom: 32px;
    }
  }

  .person-first {
    justify-content: flex-start !important;
  }

  .person {
    display: flex;
    align-items: center;
    margin-top: 25px;

    @include media-breakpoint-down(md) {
      justify-content: space-between;

      button {
        padding: 3px 9px;
        border-color: #040d0d;

        &:active {
          background: none;
          border-color: #040d0d;
        }

        img {
          width: 12px;
          height: 12px;
        }
      }
    }

    img.user-img {
      margin-right: 16px;
      border-radius: 50%;
      width: 48px;
      height: 48px;
    }

    div {
      h6 {
        font-weight: 700;
        font-size: 1.125rem;
        color: #040d0d;

        @include media-breakpoint-down(md) {
          font-weight: 600;
          font-size: 14px;
          margin-bottom: 5px;
        }
      }

      p {
        font-weight: 400;
        font-size: 0.875rem;
        color: #545959;
        margin-bottom: 0;

        @include media-breakpoint-down(md) {
          font-size: 12px;
        }
      }
    }
  }
}

.breadcrumb {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    @media (max-width: 1360px) {
      font-size: 12px;
    }

    &:last-child {
      color: #040d0d;
      margin-left: 5px;
      font-weight: 500;
      font-size: 18px;

      @media (max-width: 1360px) {
        font-size: 12px;
      }
    }

    a {
      color: #545959;
      font-weight: 400;
      font-size: 18px;
      text-decoration: none;
      margin-right: 5px;

      @media (max-width: 1360px) {
        font-size: 12px;
      }
    }
  }
}

.trusted {
  margin-bottom: 7.5rem;

  @include media-breakpoint-down(md) {
    margin-bottom: 64px;
  }

  h3 {
    font-weight: 700;
    font-size: 1.25rem;
    color: #040d0d;
    text-align: center;
    margin-bottom: 3rem;

    @include media-breakpoint-down(md) {
      margin-bottom: 32px;
      font-weight: 700;
      font-size: 16px;
    }
  }

  img {
    margin-right: 80px;
    max-width: 100px;
    max-height: 100px;
  }

  .marquee {
    .initial-child-container {
      align-items: center;
    }
  }
}

.foreground {
  .box {
    background: #00415e;
    padding: 50px 0;
    margin-bottom: -50px;
    margin-top: 7.5rem;

    @include media-breakpoint-down(md) {
      margin-bottom: 0;
    }

    h2 {
      font-size: 1.9375rem;
      font-weight: 500;
      color: #ffffff;
      text-align: center;

      @media (max-width: 1360px) {
        font-size: 22px;
      }
    }

    p {
      font-weight: 400;
      font-size: 1rem;
      color: #ffffff;
      text-align: center;

      @media (max-width: 1360px) {
        font-size: 12px;
      }
    }
  }
}

.btn-black {
  background: #080a0e !important;
  color: #ffffff !important;
}

.d-break {
  display: none;

  @media (max-width: 1366px) {
    display: block;
  }
}

.about-text {
  @media (max-width: 1360px) {
    font-size: 12px;
  }
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px !important;
}

.d-break-home {
  display: block;

  @media (max-width: 991px) {
    display: none;
  }

  @include media-breakpoint-down(md) {
    display: block;
  }
}

.hubspot-form {
  .input {
    input {
      border: none;
      border-bottom: 1px solid #080a0e;
      outline: none;
      width: 100%;
      background: transparent;
      padding: 10px 0;
      color: #8c8c8c;
      font-size: 17px;

      @media (max-width: 1360px) {
        font-size: 12px;
      }
    }
  }
}

.animation {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes slideDownIn {
  0% {
    -webkit-transform: translateY(-20px);
  }

  100% {
    -webkit-transform: translateY(0);
  }

  0% {
    -webkit-transform: translateY(-20);
  }
}

@keyframes slideDownIn {
  0% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }

  0% {
    transform: translateY(-20px);
  }
}

.slideDownIn {
  -webkit-animation-name: slideDownIn;
  animation-name: slideDownIn;
}

ul.paginations {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 100px;

  @include media-breakpoint-down(md) {
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 70px;
  }

  li {
    margin-bottom: 0;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    a {
      font-weight: 700;
      font-size: 14px;
      color: #212b36;
      border: 1px solid #dfe3e8;
      border-radius: 4px;
      padding: 5px 10px;
      text-decoration: none;

      &:hover {
        border: 1px solid #00415e;
        color: #00415e;
      }
    }

    a.active {
      border: 1px solid #00415e;
      color: #00415e;
    }

    a.dots {
      pointer-events: none;
    }

    a.prev,
    a.next {
      color: #c4cdd5;

      &:hover {
        color: #00415e;
      }

      @include media-breakpoint-down(md) {
        color: #c4cdd5;
      }
    }

    a.disabled {
      background: #919eab;
      color: #c4cdd5;
      border: 1px solid #919eab;
      pointer-events: none;

      @include media-breakpoint-down(md) {
        background: #919eab;
        opacity: 0.5;
      }
    }
  }
}

.casestudies {
  padding: 10rem 0;

  @media (max-width: 992px) {
    padding: 50px 0;
  }

  @include media-breakpoint-down(md) {
    padding: 64px 0;
  }

  .container {
    @include media-breakpoint-down(md) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  h2.heading {
    @include media-breakpoint-down(md) {
      margin-bottom: 16px !important;
    }
  }
  .first-row-sec-item {
    @include media-breakpoint-down(lg) {
      margin-top: 1.25rem !important;
    }
  }

  .first-row {
    height: 500px;

    @media (max-width: 992px) {
      height: auto;
    }

    @include media-breakpoint-down(lg) {
      height: 443px;
    }

    img.image {
      height: 500px;
      width: 100%;

      @media (max-width: 992px) {
        height: auto;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .img-mob {
      height: 180px !important;
    }
  }

  .second-row {
    height: 250px;

    @include media-breakpoint-down(md) {
      h4 {
        margin-bottom: 0;
      }
    }

    @media (max-width: 992px) {
      height: auto;
    }

    img {
      // height: 250px;
      width: 100%;

      @media (max-width: 992px) {
        height: auto;
      }
    }
  }

  .box-min-height {
    @include media-breakpoint-down(lg) {
      height: 180px;
    }
  }

  .box {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;

    .text-content {
      background: linear-gradient(
        1.39deg,
        rgba(10, 60, 71, 0.8) 1.41%,
        rgba(5, 29, 34, 0.504) 27.34%,
        rgba(0, 0, 0, 0) 99.04%
      );
      position: absolute;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      padding: 2.5rem;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      @include media-breakpoint-down(md) {
        padding: 0 24px 24px;
      }

      .blue-link {
        @include media-breakpoint-down(md) {
          padding: 16px 18px;
        }
      }

      h3 {
        font-weight: 500;
        font-size: 1.9375rem;
        color: #ffffff;

        @include media-breakpoint-down(md) {
          font-weight: 500;
          font-size: 21px;
          margin-bottom: 4px;
        }
      }

      h4 {
        font-weight: 500;
        font-size: 1.9375rem;
        color: #ffffff;

        @include media-breakpoint-down(lg) {
          font-size: 14px;
          // overflow: hidden;
          // text-overflow: ellipsis;
          // white-space: nowrap;
          height: 100%;
          align-items: center;
          display: flex;
        }
      }

      p {
        font-weight: 400;
        font-size: 1rem;
        color: #ffffff;
        opacity: 0.8;
        margin-bottom: 0;

        @include media-breakpoint-down(md) {
          font-size: 12px;
        }
      }
    }
  }

  .arrow-casestudy {
    @include media-breakpoint-up(xl) {
      width: 21px;
      height: 21px;
    }
  }
}

@keyframes glow {
  0% {
    box-shadow: 0 0 50px rgba(50, 160, 50, 0.9);
  }

  50% {
    box-shadow: 0 0 50px rgba(50, 160, 50, 0.2);
  }

  100% {
    box-shadow: 0 0 50px rgba(50, 160, 50, 0.9);
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.02, 1.02);
  }

  100% {
    transform: scale(1, 1);
  }
}

@media screen and (max-width: 1400px) and (min-width: 1200px) {
  .swiper-slide > div:first-child .box {
    padding-top: 0;
  }

  .swiper-slide > div .box {
    margin-bottom: 25px;
    padding-top: 25px;
  }
}

.modal-schedule-call {
  .modal-body {
    background-color: transparent;
    position: relative;

    .modal-close {
      display: block;
      text-align: right;
      position: absolute;
      right: 0;
      cursor: pointer;

      @media (min-width: 467px) and (max-width: 490px) {
        right: 10px;
      }

      @media (min-width: 491px) and (max-width: 520px) {
        right: 20px;
      }

      @media (min-width: 521px) and (max-width: 566px) {
        right: 40px;
      }

      @media (min-width: 567px) and (max-width: 991px) {
        right: 30px;
      }
    }
  }

  .modal-content {
    background-color: transparent;
    border: none;
    border-radius: 0;
  }
}

.logos-footer {
  a {
    // margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }
  }

  img {
    width: auto;
    height: fit-content;

    @include media-breakpoint-down(md) {
      width: auto;
      margin-bottom: 10px;
      // height: 2rem;
    }
  }
}

.about-image1 {
  border-radius: 100.583px 0px 0px 0px;
  width: 70%;
}

.about-image2 {
  border-radius: 0px 58.0833px 0px 48.875px;
  position: absolute;
  right: 0;
  bottom: -100px;
  width: 40%;
}

.get-in-touch {
  background: #3ebfc1;
  padding: 1rem 4rem;
  color: #000000;
  display: inline-block;
  font-size: 1rem;
  border: 1px solid #3ebfc1;
  margin-right: 1.44rem;
  white-space: nowrap;

  &:hover {
    background: #57ebed;
  }

  @include media-breakpoint-down(md) {
    padding: 16px 24px;
  }

  &:hover {
    color: #000000;
  }
}

.drop-email {
  background: transparent;
  padding: 1rem 4rem;
  color: #3ebfc1;
  display: inline-block;
  font-size: 1rem;
  border: 1px solid #3ebfc1;
  white-space: nowrap;

  &:hover {
    background: #1a1a1a;
  }

  @include media-breakpoint-down(md) {
    padding: 16px 24px;
  }

  &:hover {
    color: #3ebfc1;
  }
}

.static-header {
  padding: 60px 0;
  background: #00415e;

  h1,
  p {
    color: #ffffff;
  }

  a {
    color: #36c0c2;

    &:hover {
      color: #258586;
      text-decoration: underline;
    }
  }
}

.static-header-content {
  a {
    color: #36c0c2;

    &:hover {
      color: #258586;
      text-decoration: underline;
    }
  }
}

.cookie-btn {
  background-color: transparent;
  color: #fff !important;
  /* Button text color */
  padding: 8px 15px !important;
  border: 2px solid #fff !important;
  /* White border */
  border-radius: 20px !important;
  /* Adjust border-radius for a round shape */
  cursor: pointer;
  min-width: 7em;

  // white-space: nowrap;
  &:focus {
    background-color: #fff !important;
    /* White background on hover */
    color: #000 !important;
  }

  @media (max-width: 1000px) {
    font-size: 12px !important;
  }

  &:hover {
    background-color: #fff !important;
    /* White background on hover */
    color: #000 !important;
  }

  /* Text color on hover */
  // transition: background-color 0.3s, color 0.3s ;
}

.cookie-container {
  max-width: 1420px;
}

.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  /* Text color */
  padding: 15px;
  text-align: center;
  z-index: 1000;

  /* Adjust the z-index as needed */
  @include media-breakpoint-down(md) {
    padding: 17.5px 16px;
    position: fixed;
  }

  a {
    text-decoration: underline !important;
  }

  h4 {
    font-weight: 800;
    font-size: 17px;
    margin-bottom: 0;

    @include media-breakpoint-down(md) {
      font-weight: 800;
      font-size: 16px;
      color: #f1efef;
      margin-bottom: 16px;
    }
  }

  a {
    color: #3ebfc1;
    font-size: 17px;
    font-weight: 300;
    text-decoration: none;

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }

    img {
      width: 20px;
      margin-left: 5px;
    }
  }

  #progress {
    width: 0%;
    background-color: #3ebfc1;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    transition: width 2s ease;
  }

  button {
    padding: 16px 131px;
    background-color: transparent;
    font-size: 16px;
    color: #080a0e;
    border: none;
    position: relative;
    transition: width 2s ease;

    span {
      position: relative;
      z-index: 3;
    }

    @media (max-width: 1360px) {
      padding: 10px 131px;
    }

    @include media-breakpoint-down(md) {
      padding: 12px 50px;
    }
  }
}

.float-right {
  float: right;
}

.footer-banner-right-icon {
  position: absolute;
  right: 10px;
  top: 30px;
}

.logo-white {
  width: 8.18606rem;
  margin-bottom: 32px;
}

.pointer {
  cursor: pointer;
}

/* 5 Columns */
.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.col-xs-15 {
  width: 20%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-15 {
    width: 20%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-15 {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-15 {
    width: 20%;
    float: left;
  }
}

.job-listing {
  padding: 50px 0 160px 0;

  @include media-breakpoint-down(md) {
    padding: 64px 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      border: 1px solid #e1e1e1;

      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.5rem 20px 20px;
        background: #ffffff;
        text-decoration: none;
        color: #000000;

        @include media-breakpoint-down(md) {
          display: block;
        }

        &:hover {
          background: #36c0c2;
          color: #ffffff;
          text-decoration: none;
        }

        h5 {
          font-weight: 600;
        }

        p {
          margin: 0;
        }
      }
    }
  }
}

.padding16 {
  @include media-breakpoint-down(md) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.p160 {
  padding: 160px 0;
}

.job-contents {
  input,
  select {
    border-width: 0 0 2px !important;
    border-radius: 0;
    box-shadow: none;
    outline: none;

    &:focus {
      outline: none;
      border-color: #36c0c2;
      box-shadow: none;
    }
  }
}

.modal-open {
  overflow: scroll !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  padding-right: 0 !important;

  .fixed-top {
    position: absolute !important;
  }
}

.scale-container {
  overflow: hidden;
  position: relative;

  .bg-scale-img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 1s;
  }

  &:hover {
    .bg-scale-img {
      transform: scale(1.1) !important;
    }
  }

  .bg-resource-scale-img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 1s;
  }

  &:hover {
    .bg-resource-scale-img {
      transform: scale(0.9) !important;
    }
  }
}

.mt-20 {
  margin-top: 20px;
}

.resource-box {
  margin-bottom: 48px;
  transition: all 1s;
}

header {
  #menu {
    .dropdown-menu {
      @include media-breakpoint-down(md) {
        padding-top: 0 !important;
      }
    }

    .menu-dropdown {
      .nav-pills {
        padding-top: 10px;
      }
    }
  }
}

.hover-undeline {
  &:hover {
    text-decoration: underline;
    color: #ffffff;
  }
}

.menu-top-height {
  transition: all 0.2s ease-in;

  @include media-breakpoint-down(md) {
    margin-top: 70.38px;
  }

  @include media-breakpoint-only(md) {
    margin-top: 61.73px;
  }

  @include media-breakpoint-up(md) {
    margin-top: 61.73px;
  }

  @media (min-width: 1361px) {
    margin-top: 68px;
  }
}

.h-md-100 {
  @include media-breakpoint-up(md) {
    height: 100%;
  }
}

.btn-blue {
  background: #3ebfc1;
  color: #000000 !important;
  display: inline-block;
  font-size: 16px;
  border: 1px solid #3ebfc1;
  padding: 10px 20px;

  &:hover {
    text-decoration: none !important;
    background: #57ebed !important;
    border: 1px solid #57ebed !important;
  }
}

.mb-2-rem {
  @include media-breakpoint-up(md) {
    margin-bottom: 2rem !important;
  }
}

.my-2-rem {
  @include media-breakpoint-up(md) {
    margin: 2rem 0 !important;
  }
}

.mb-2-rem-fixed {
  margin-bottom: 2rem;
}

.mb-25-rem-fixed {
  margin-bottom: 20px !important;
}

.mt-25-rem-fixed {
  margin-top: 1.5rem !important;
}
.mt-25-rem-copy-fixed {
  margin-top: 1.5rem !important;
  @media (max-width: 768px) {
    margin-top: 16px !important;
    margin-bottom: 24px !important;
  }
}
.mx-25-rem-fixed {
  margin: 0 10px !important;
  opacity: 0.6;
}

.d-break-new {
  display: none;

  @include media-breakpoint-up(xxl) {
    display: block;
  }
}

.d-break-lara {
  display: none;

  @include media-breakpoint-up(lg) {
    display: block;
  }
}

.mt-2-rem {
  @include media-breakpoint-up(md) {
    margin-top: 2rem !important;
  }
}
.mt-4-rem {
  @include media-breakpoint-up(md) {
    margin-top: 80px !important;
  }
}
.mt-10-rem {
  @include media-breakpoint-up(md) {
    margin-top: 10rem !important;
  }
}
.mb-4-rem {
  margin-bottom: 3rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 4rem;
  }
}

.gx-5-rem {
  --bs-gutter-x: 3rem;

  @include media-breakpoint-up(md) {
    --bs-gutter-x: 5rem;
  }
}

.testi-main-img {
  max-height: 19.6875rem;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 600 !important;
}

.case-mb-4 {
  margin-bottom: 4rem;
}
.case-mb {
  margin-bottom: 50px;
  @media (max-width: 991px) {
    margin-bottom: 20px;
  }
}

.conversation {
  background: url("/assets/images/laravel-question-bg1.png");
  padding: 4.25rem 0 15.84rem;
  background-size: cover; /* Ensures the image covers the element */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  height: 100%; /* Set element height */
  width: 100%;
  @include media-breakpoint-down(lg) {
    padding: 3rem 0 19rem;
  }
  @media (min-width: 1200px) and (max-width: 1400px) {
    padding: 2.25rem 0 15.84rem;
  }

  .container {
    @include media-breakpoint-down(md) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .sub-title {
      font-size: 24px;
      font-weight: 500;
      line-height: 43.94px;
      letter-spacing: -0.04em;
      text-align: center;
      color: white;
      @include media-breakpoint-down(lg) {
        font-size: 1.15rem;
        line-height: 2rem;
      }
      @media (min-width: 1200px) and (max-width: 1400px) {
        font-size: 1.25rem;
      }
    }
  }

  .row {
    gap: 0.5rem;
    @include media-breakpoint-down(md) {
      gap: 1rem;
    }

    .heading {
      color: #ffffff;
      font-size: 3rem;
      font-weight: 500;
      @media (min-width: 1200px) and (max-width: 1400px) {
        font-size: 2.25rem;
      }

      @include media-breakpoint-down(lg) {
        font-size: 1.5rem;
        text-align: center;
        line-height: 1.75rem;
        letter-spacing: -0.045rem;
      }
    }

    .stack-slider-form {
      @include media-breakpoint-down(lg) {
        height: 25.75rem !important;
      }
    }

    .confirm-form {
      @include media-breakpoint-down(lg) {
        height: 12.75rem !important;
      }
    }

    .stack-slider {
      position: relative;
      height: 20rem;

      @include media-breakpoint-down(lg) {
        height: 20.75rem;
      }

      .top-30 {
        @include media-breakpoint-down(lg) {
          top: 10px !important;
        }
      }

      .top-prev {
        @include media-breakpoint-down(lg) {
          top: -40px !important;
        }
      }

      .stack-item {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 0;
        transition: left 0.5s;
        border-radius: 2.875rem;

        @include media-breakpoint-down(lg) {
          transition: transform 0.5s;
        }
      }

      .stack-item.active {
        z-index: 2;
        transform: translateX(0) translateY(0) scale(1);
        background: rgba(255, 255, 255, 0.21);
        backdrop-filter: blur(35.5px);

        @include media-breakpoint-down(lg) {
          width: 100%;
        }
      }

      .stack-item.next {
        z-index: 1;
        background: rgba(255, 255, 255, 0.05);
        backdrop-filter: blur(35.5px);
        top: -40px;
        left: 40px;

        @include media-breakpoint-down(lg) {
          width: 100%;
          transform: translateX(0%) translateY(-50%) scale(0.8);
          top: 80px;
          left: 0;
        }
      }

      .stack-item.previous {
        z-index: 0;
        background: rgba(255, 255, 255, 0.05);
        backdrop-filter: blur(35.5px);
        top: -80px;
        left: 80px;

        @include media-breakpoint-down(lg) {
          transform: translateX(6%) translateY(-48%) scale(0.8);
          width: 90%;
          top: 40px;
          left: 0;
        }
      }

      .item-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        hr {
          backdrop-filter: blur(19.5px);
          border-top: 1px solid #ffffff;
          padding: 0;
          opacity: 1;
          margin: 0;

          @include media-breakpoint-down(lg) {
            display: none;
          }

          @media (min-width: 1200px) and (max-width: 1399px) {
            margin: 0 31px;
          }
        }

        .invisible {
          .item-text {
            line-height: 1.5em;
            height: 7em;
            overflow: hidden;
          }
        }
      }

      .item-text {
        color: #ffffff;
        text-align: center;
        font-size: 1.5rem;
        font-weight: 600;
        padding: 3.12rem 4.12rem;

        @include media-breakpoint-down(lg) {
          padding: 3.06rem 3.37rem 0;
          font-size: 1.25rem;
        }

        p {
          text-align: left;

          a {
            color: #ffffff;
            text-decoration: none;
            font-size: 1rem;
          }

          img {
            width: 20px;
            margin-right: 10px;
            transform: rotate(180deg);
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
              saturate(0%) hue-rotate(152deg) brightness(103%) contrast(103%);
          }
        }

        form {
          input {
            background: transparent;
            border: none;
            border-bottom: 1px solid #ffffff;
            outline: none;
            font-size: 1.2rem;
            width: 100%;
            color: #ffffff;
            border-radius: 0;

            @include media-breakpoint-down(xxl) {
              font-size: 1rem;
            }

            &::placeholder {
              color: #ffffff;
              opacity: 1;
            }

            &:-ms-input-placeholder {
              color: #ffffff;
            }

            &::-ms-input-placeholder {
              color: #ffffff;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }

          button {
            padding: 0.47019rem 3.05606rem;
            border-radius: 0.70525rem;
            background: #4ac668;
            font-size: 1.2rem;
            font-weight: 600;
            border: none;
            margin-top: 10px;

            @include media-breakpoint-down(lg) {
              display: block;
              width: 100%;
              font-size: 1.125rem;
              padding: 0.532rem 3.05606rem;
              margin-bottom: 20px;
            }
          }
        }
      }

      .controls {
        display: flex;
        justify-content: center;
        gap: 3.64rem;
        padding: 3.12rem 4.12rem;

        @include media-breakpoint-down(lg) {
          display: block;
          padding: 2.56rem 3.37rem 2.31rem;
        }

        button {
          padding: 0.47019rem 3.05606rem;
          border-radius: 0.70525rem;
          background: #4ac668;
          font-size: 1.5rem;
          font-weight: 600;
          border: none;

          @include media-breakpoint-down(lg) {
            display: block;
            width: 100%;
            font-size: 1.125rem;
            padding: 0.532rem 3.05606rem;
          }

          &:first-child {
            background: #4ac668;

            @include media-breakpoint-down(lg) {
              margin-bottom: 1rem;
            }
          }

          &:last-child {
            background: #ec2621;
          }
        }
      }
    }
  }
}

.laravel-banner {
  button {
    &:last-child {
      background: #ea1e1e;
    }
  }
}

.line-normal {
  line-height: normal;
}

.contact-new {
  margin-top: 7.5rem;
}

.animated-slider {
  .slider-container {
    position: relative;
    width: 100%;
    overflow: hidden;

    .slider-content {
      display: flex;
      flex-direction: row;
      transition: transform 0.3s ease-in-out;
    }

    .slide {
      flex: 0 0 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 500px;
      font-size: 24px;
      color: #fff;
      background-repeat: no-repeat;
      background-size: cover;
      visibility: hidden;

      .text-content {
        background: linear-gradient(
          1.39deg,
          rgba(10, 60, 71, 0.8) 1.41%,
          rgba(5, 29, 34, 0.504) 27.34%,
          rgba(0, 0, 0, 0) 99.04%
        );
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        padding: 2.5rem;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        h3 {
          font-weight: 500;
          font-size: 1.9375rem;
          color: #ffffff;
        }

        p {
          font-weight: 400;
          font-size: 1rem;
          color: #ffffff;
          opacity: 0.8;
          margin-bottom: 0;
        }

        .blue-link {
          background: #ff2d20;
          border-radius: 0.75rem;
          display: inline-flex;

          img {
            filter: none;
          }
        }
      }
    }

    .slide.active {
      transform: translateX(0);
      visibility: visible;
    }

    .progress-bar {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 5px;
      background-color: #e9ecef;
    }

    .progress {
      height: 100%;
      background-color: #f47a7a;
    }
  }
}

#hubspot-messages-iframe-container {
  visibility: hidden;
}

.visible-chat {
  visibility: hidden !important;
  right: 0;
  z-index: 999;
}

.invisible-chat {
  visibility: hidden !important;
  z-index: 998;
}

.hs-messages-widget-open {
  .visible-chat {
    visibility: visible !important;
  }
}

.banner-slide {
  animation: slideFromLeftToRight 1.8s ease;
  z-index: 0;

  @include media-breakpoint-down(sm) {
    animation: smooth-appear 0.5s ease forwards;
    z-index: 0;
  }
}

@keyframes slideFromLeftToRight {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(100%);
    opacity: 0;
    display: none;
  }
}

.banner-small {
  position: fixed;
  right: 88px;
  bottom: 15px;
  padding: 17px;
  z-index: 999;

  @include media-breakpoint-down(md) {
    display: none;
  }

  a {
    &:first-child {
      img {
        filter: brightness(0) saturate(100%) invert(19%) sepia(16%)
          saturate(5449%) hue-rotate(169deg) brightness(97%) contrast(102%);
      }
    }

    &:nth-child(2) {
      margin: 0 1rem;
    }

    &:nth-child(2),
    &:last-child {
      background: #00415e;
      padding: 14px;
      border-radius: 50%;
    }
  }
}

.border-radius-50 {
  border-radius: 50%;
}

.case-more-btn {
  background: #00415e;
  margin-right: 1rem;

  @include media-breakpoint-down(md) {
    margin-right: 0;
  }
}

.case-more-btn {
  &:hover {
    background-color: #004e71 !important;
    border: 1px solid #efeff0;
  }
}

.banner-expand {
  animation: slideFromRightToLeft 1.8s ease;
  opacity: 1;
  z-index: 10;
}

.banner-justify-between {
  justify-content: space-between !important;
  @media (max-width: 1400px) {
    justify-content: space-evenly !important;
  }
}

@keyframes slideFromRightToLeft {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

.rotate-180 {
  transform: rotate(180deg);
}

input[type="text"],
textarea {
  color: #8c8c8c;
  font-size: 0.75rem;
  font-weight: 400;
  border: none;
  border-bottom: 1px solid #080a0e;
  padding-bottom: 0.71rem;
  outline: none;
  width: 100%;
  background: transparent;

  @include media-breakpoint-up(xxl) {
    font-size: 1.0625rem;
  }

  &::-webkit-input-placeholder {
    color: #8c8c8c;
    font-size: 0.75rem;
    font-weight: 400;

    @include media-breakpoint-up(xxl) {
      font-size: 1.0625rem;
    }
  }

  &:-ms-input-placeholder {
    color: #8c8c8c;
    font-size: 0.75rem;
    font-weight: 400;

    @include media-breakpoint-up(xxl) {
      font-size: 1.0625rem;
    }
  }

  &::placeholder {
    color: #8c8c8c;
    font-size: 0.75rem;
    font-weight: 400;

    @include media-breakpoint-up(xxl) {
      font-size: 1.0625rem;
    }
  }
}

input[type="email"],
textarea {
  color: #8c8c8c;
  font-size: 0.75rem;
  font-weight: 400;
  // border: none;
  border-bottom: 1px solid #080a0e;
  // padding-bottom: 0.71rem;
  outline: none;
  width: 100%;
  background: transparent;

  @include media-breakpoint-up(xxl) {
    font-size: 1.0625rem;
  }

  &::-webkit-input-placeholder {
    color: #8c8c8c;
    font-size: 0.75rem;
    font-weight: 400;

    @include media-breakpoint-up(xxl) {
      font-size: 1.0625rem;
    }
  }

  &:-ms-input-placeholder {
    color: #8c8c8c;
    font-size: 0.75rem;
    font-weight: 400;

    @include media-breakpoint-up(xxl) {
      font-size: 1.0625rem;
    }
  }

  &::placeholder {
    color: #8c8c8c;
    font-size: 0.75rem;
    font-weight: 400;

    @include media-breakpoint-up(xxl) {
      font-size: 1.0625rem;
    }
  }
}

.background-black {
  background-color: #000 !important;
}
.landing-contact-btn-blck {
  background-color: #000 !important;

  @include media-breakpoint-down(lg) {
    background: #00415e !important;
  }
}
.contact-form {
  p {
    margin-bottom: 3.12rem;

    @include media-breakpoint-down(xxl) {
      margin-bottom: 2.21rem;
    }
  }

  @include media-breakpoint-down(lg) {
    margin-top: 2rem;
  }

  .first-half {
    p {
      margin-right: 2rem;

      @include media-breakpoint-down(lg) {
        margin-right: 0;
      }
    }
  }

  .second-half {
    p {
      margin-left: 2rem;

      @include media-breakpoint-down(lg) {
        margin-left: 0;
      }
    }
  }

  button {
    padding: 1rem 1.5rem;
    background: #00415e;
    color: #ffffff;
    font-size: 1rem;
    font-weight: 700;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    margin-top: 1rem;

    &:hover,
    &:focus {
      background: #004e71;
    }
  }
}

#contact-new {
  @media (max-width: 1400px) {
    min-height: 635px;
  }
  min-height: 650px;
}

.refer-form {
  h6 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19.36px;
    color: #00415e;
  }

  p {
    margin-bottom: 48px;

    @include media-breakpoint-down(xxl) {
      margin-bottom: 2.21rem;
    }
  }

  @include media-breakpoint-down(lg) {
    margin-top: 2rem;
  }

  .first-half {
    p {
      margin-right: 2rem;

      @include media-breakpoint-down(lg) {
        margin-right: 0;
      }
    }
  }

  .second-half {
    p {
      margin-left: 2rem;

      @include media-breakpoint-down(lg) {
        margin-left: 0;
      }
    }
  }

  .customer-text {
    font-weight: 400;
    // color: #8C8C8C;
    font-size: 12px;
  }

  .learn-text {
    font-weight: 400;
    color: #8c8c8c;
    font-size: 16px;

    a {
      font-weight: 700;
      // color: #00415D;
      color: #8c8c8c;
      font-size: 18px;
      text-decoration: none;
    }

    .animated-button {
      // background-color: white;
      // color: black;
      border: 0;
      outline: 0;
      position: relative;
      padding: 0;
      transition: color 0.5s ease;
      border-radius: 0;
      display: inline-block;
      border-bottom: 2px solid #2c888929;
      position: relative;
    }

    .animated-button:active {
      background-color: unset;
    }

    .animated-button:hover {
      color: #2c8889;
    }

    .animated-button::before {
      content: "";
      position: absolute;
      bottom: -2px;
      z-index: 10000000;
      left: 0;
      width: 0;
      height: 2px;
      background-color: #2c8889;
      transition: width 0.5s ease;
    }

    .animated-button:hover::before {
      width: 100%;
      height: 2px;
      background-color: #2c8889;
    }
  }

  button {
    padding: 1rem 1.5rem;
    background: #00415e;
    color: #ffffff;
    font-size: 1rem;
    font-weight: 700;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    margin-top: 1rem;

    &:hover,
    &:focus {
      background: #004e71;
    }
  }
}

.home-contact {
  p {
    margin-bottom: 2rem;
    margin-top: 0;
  }

  .first-half {
    p {
      margin-right: 1rem;

      @include media-breakpoint-down(lg) {
        margin-right: 0;
      }
    }
  }

  .second-half {
    p {
      margin-left: 1rem;

      @include media-breakpoint-down(lg) {
        margin-left: 0;
      }
    }
  }
}

.why-link {
  a {
    color: #36c0c2;
    text-decoration: none;

    &:hover {
      color: #258586;
    }
  }
}

.normal-text {
  p {
    font-weight: 400;
    font-size: 1rem;
    color: #545959;

    @include media-breakpoint-down(md) {
      font-size: 12px;
    }
  }
}

.contact-form-check {
  label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}

.modal-need-help {
  .modal-body {
    background-color: transparent;
    position: relative;
    padding: 0;

    .modal-close {
      display: block;
      text-align: right;
      position: absolute;
      right: -14px;
      top: -14px;
      cursor: pointer;
    }
  }

  .modal-content {
    border: 1px solid #b9b9b9;
    background: #ffffff;
    box-shadow: 0px 4.5288px 3.62304px 0px rgba(0, 0, 0, 0.02),
      0px 12.52155px 10.01724px 0px rgba(0, 0, 0, 0.03),
      0px 30.14707px 24.11766px 0px rgba(0, 0, 0, 0.03),
      0px 100px 80px 0px rgba(0, 0, 0, 0.05);
    border-radius: 0;

    @include media-breakpoint-down(lg) {
      margin: 20px;
    }

    .left-side-content {
      img {
        @include media-breakpoint-down(lg) {
          height: 200px;
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .right-side-content {
      @include media-breakpoint-down(lg) {
        padding: 1.5rem;
      }

      @include media-breakpoint-up(md) {
        margin-right: 1.5rem;
      }

      h3 {
        color: #00415e;
        font-size: 2rem;
        font-weight: 500;

        @include media-breakpoint-down(lg) {
          font-size: 1.8rem;
        }
      }

      p {
        color: #545959;
        font-size: 1rem;
        font-weight: 400;
        margin: 2.25rem 0;

        @include media-breakpoint-down(lg) {
          font-size: 1rem;
          margin: 1.5rem 0;
        }
      }

      .forms {
        position: relative;

        input {
          border: none;
          border-bottom: 0.125rem solid #000000;
          padding: 0.62rem 0;
          font-size: 1rem;
          padding-right: 80px;
          border-radius: 0;

          @include media-breakpoint-down(lg) {
            font-size: 0.75rem;
          }
        }

        button {
          position: absolute;
          right: 0;
          top: 0.62rem;
          bottom: 0.62rem;
          background: #004e71;
          border: none;
          box-shadow: none;
          font-size: 1rem;
          padding-top: 0;
          padding-bottom: 0;
          height: fit-content;

          @include media-breakpoint-down(lg) {
            font-size: 0.75rem;
          }

          &:hover {
            background: #00415e;
          }
        }
      }
    }
  }
}

.newsletter-form {
  text-align: start;

  input {
    border-bottom: 0.125rem solid #ffffff;
    color: #919191;
    font-size: 1rem;
    font-weight: 400;
    padding-bottom: 10px;
    padding-top: 0;
    line-height: 0;
    border-radius: 0;

    @include media-breakpoint-down(xxl) {
      font-size: 1rem;
    }
  }

  button {
    background: #3ebfc1;
    color: #000000;
    font-size: 16px;
    border: none;
    box-shadow: none;
    padding: 5px 15px;
    font-weight: 500;
    transition: all 0.2s ease-in;
    margin-top: 0;
    border-radius: 0;

    &:hover,
    &:focus {
      background: #57ebed;
      color: #000000;
    }
  }
}

.invalid-feedback {
  color: red;
}

.color-conver {
  color: #ff9900 !important;
}

.flag {
  &.hr {
    margin: 0 !important;
    margin-right: 7px !important;
  }
}

.phInput {
  margin-left: 1rem;

  @media (max-width: 990px) {
    margin-left: 0 !important;
  }
}

.contact-us-phn {
  margin-left: 1.9rem;

  @media (max-width: 990px) {
    margin-left: 0 !important;
  }
}

.btn-header-content {
  @media (max-width: 400px) {
    font-size: 12px !important;
  }
}

.cookie-policy-accrdion {
  border-bottom: none;

  // height: 27vh;
  // border: 1px solid black;
  .cookie-accordion-card {
    border-style: none;
    padding-bottom: 15px;
  }
}

.cookie-accrdion {
  border-bottom: none;
  height: 27vh;

  .accordion-header {
    padding: 12px !important;

    .accordion-button {
      color: black !important;
      font-size: 19px !important;
    }
  }

  .form-check-input:checked {
    background-color: #004e71;
    border-color: #004e71;
  }

  .accordion-item {
    background: transparent;
    border: none;
    border-bottom: 1px solid #bcbebf;
    padding-bottom: 20px;

    .accordion-body {
      background-color: #f0f2f2 !important;
      color: black !important;
    }
  }
}

.btn-preference {
  border-style: none;
  border-radius: 3px;
  background-color: black;

  &:hover,
  &:focus {
    background: #ffffff;
    color: #000000;
    border-style: solid;
    border-color: black;
  }
}

.btn-preference-deny {
  border-color: black;
  color: rgb(0, 0, 0);
  border-radius: 3px;
  background-color: transparent;

  &:hover,
  &:focus {
    background: #050505;
    color: #ffffff;
  }
}

.cookie-data {
  background-color: #f0f2f2;
  // max-height: 250px;
  overflow: auto;
}

.banner-content-color {
  color: #00415e;
}

.white-space-nowrap {
  white-space: nowrap;
}

.tbl-cookie {
  @media (max-width: 991px) {
    white-space: nowrap;
  }
}

.react-tel-input {
  font-family: inherit !important;

  .form-control {
    color: #8c8c8c;
    font-weight: 400;
    border: none;
    outline: none;
    width: 100%;
    background-color: inherit !important;
    font-size: 17px;
    font-family: inherit !important;
    padding-bottom: 0.71rem;

    @media (max-width: 1360px) {
      font-size: 12px !important;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 1.0625rem !important;
    }
  }

  .flag-dropdown.open {
    .selected-flag {
      background-color: inherit !important;

      &:hover,
      &:focus {
        background-color: inherit !important;
      }
    }
  }

  .flag-dropdown {
    border-radius: 0 !important;
    background-color: inherit !important;
    border-style: none !important;
    // border-bottom: 1px solid !important;
    padding-bottom: 0.4rem !important;

    .country-list {
      .country {
        &:hover {
          background-color: #f1f1f1;
        }

        &.highlight {
          background-color: lightblue;
        }
      }
    }
  }
}

.position-absolute-ll {
  z-index: 1000;
  right: 0;
  left: 0;
  // color: black;
  // opacity: 8%;
}

.margin-x {
  padding: 104px;
  @media (max-width: 768px) {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.margin-xx {
  padding-left: 104px;
  padding-right: 104px;
  // @media (max-width: 768px) {
  //   padding-left: 0px;
  //   padding-right: 0px;
  //   padding-top: 10px;
  //   padding-bottom: 10px;

  // }
}

.h-5 {
  height: 525px;
  @media (min-width: 1200px) and (max-width: 1400px) {
    height: 570px;
  }
}

// .blog-img {
//   width: 100%;
//   height: 100%;
// }

.arrow-right-border {
  border-radius: 5px;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  padding: 4px;
}

.newsletter-inputs {
  display: flex;
  align-items: center;
  border-bottom: solid 1px #313838;
  padding-bottom: 20px;
}
.newsletter-inputs .form-control {
  background: #253232;
  border: 0;
  padding: 16px;
  height: 48px;
  // color: #fff;
  color: #fcfcfc;
  // font-family: "Inter Tight";
  font-size: 16px;
  font-style: normal;
  font-weight: 440;
  line-height: 100%; /* 16px */
}
.newsletter-inputs .btn {
  width: 116px;
  padding: 16px;
  height: 48px;
  margin-left: 16px;
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #040c0c;
  // font-family: "Inter Tight";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 16px */
}
span.sm-title {
  color: #fcfcfc;
  font-size: 20px;
  opacity: 0.7;
  margin-right: 20px;
  white-space: nowrap;
  letter-spacing: 0px !important;
}
.address-boxes {
  display: flex;
  align-items: center;
}
.address-boxes a {
  padding: 0 14px;
  position: relative;
}
.address-boxes a:first-child {
  padding-left: 0;
}

.address-boxes a:after {
  content: "";
  width: 1px;
  height: 78%;
  background: #fff;
  position: absolute;
  right: 0;
  opacity: 0.4;
  bottom: 3px;
}

.address-boxes a:last-child:after {
  display: none;
}
.rating-item {
  margin-top: 4rem;
}
.explore-more-btn {
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: var(--Round-radi-5xs, 2px);
  border: 1px solid #040c0c;
  background-color: #ffffff;
  color: #040c0c;
  // font-family: "Inter Tight";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  /* 16px */
  margin-top: 40px;

  @media (max-width: 768px) {
    padding: 12px 24px;
    font-size: 14px;
    margin-top: 32px;
  }
}

.explore-more-btn:hover {
  &:hover {
    background-color: #3ebfc1 !important;
    border: 1px solid #efeff0;
  }
}
.footer-contact {
  background: #fff;
  padding: 1rem 4rem;
  color: #040c0c;
  display: inline-block;
  font-size: 1rem;
  // border: 1px solid #3ebfc1;
  margin-right: 24px;
  white-space: nowrap;
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: var(--Round-radi-5xs, 2px);
  background: #fff;
  color: #040c0c;
  // font-family: "Inter Tight";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 16px */

  &:hover {
    background: #3ebfc1;
  }

  @include media-breakpoint-down(md) {
    padding: 16px 24px;
  }

  &:hover {
    color: #000000;
  }
}
.footer-email {
  background: transparent;
  padding: 1rem 4rem;
  color: #3ebfc1;
  display: inline-block;
  font-size: 1rem;
  // border: 1px solid #3ebfc1;
  white-space: nowrap;
  border-radius: var(--Round-radi-5xs, 2px);
  border: 1px solid #fcfcfc;
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fcfcfc;
  // font-family: "Inter Tight";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 16px */
  &:hover {
    background-color: #3ebfc1 !important;
    color: #040c0c !important;
  }

  @include media-breakpoint-down(md) {
    padding: 16px 32px;
  }

  @media (max-width: 340px) {
    padding: 16px 15px;
  }
}
.footer-container-new {
  padding-left: 104px;
  padding-right: 104px;

  @media (min-width: 768px) and (max-width: 991px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  .refer-para-footer {
    margin-top: 27.22px;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #ffffff;

    @media (min-width: 820px) {
      white-space: nowrap;
    }

    a {
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      color: #ffffff;
    }

    .animated-button {
      // background-color: white;
      // color: black;
      border: 0;
      outline: 0;
      position: relative;
      padding: 0;
      transition: color 0.5s ease;
      border-radius: 0;
      display: inline-block;
      border-bottom: 2px solid #2c888929;
      position: relative;
    }

    .animated-button:active {
      background-color: unset;
    }

    .animated-button::before {
      content: "";
      position: absolute;
      bottom: -2px;
      z-index: 10000000;
      left: 0;
      width: 0;
      height: 2px;
      background-color: #2c8889;
      transition: width 0.5s ease;
    }

    .animated-button:hover::before {
      width: 100%;
      height: 2px;
      background-color: #2c8889;
    }
  }
}

@include media-breakpoint-down(sm) {
  .newsletter-inputs {
    flex-direction: column;
    .btn {
      width: 100%;
      margin-left: 0;
      margin-top: 15px;
    }
  }
  .newsletter-form {
    .logo-white {
      width: 183px;
      margin-bottom: 26px;
    }
  }
  .rating-item {
    margin-top: 4rem;
    // flex-direction: column;
  }
}

@media (max-width: 821px) {
  .newsletter-inputs {
    flex-direction: column;
    .btn {
      width: 100%;
      margin-left: 0;
      margin-top: 15px;
    }
  }
  .rating-item {
    margin-top: 45px;
    flex-direction: column;
    // gap:12px;
    align-items: flex-start !important;
  }
  @media (max-width: 540px) {
    .rating-item {
      & > div {
        img {
          width: 110px;
        }
      }
    }
  }
  @media (max-width: 340px) {
    .rating-item {
      & > div {
        img {
          width: 80px;
        }
      }
    }
  }
  // .logos-footer a {
  //   margin-right: 12px;
  // }
}
@media (max-width: 821px) {
  .logos-footer img {
    height: 20px;
    margin-top: 15px;
  }
  .address-boxes img {
    width: 19px;
  }
  .address-boxes a {
    padding: 0 8px;
  }
}
.hr-border {
  border-top: 1px solid var(--Foundation-Green-G600, #313838);
  margin-top: 40px;
  @media (max-width: 768px) {
    margin-top: 15px;
  }
}
.get-touch {
  opacity: 70%;
  letter-spacing: 0 !important;
}
.country-names {
  opacity: 70%;
  letter-spacing: 0 !important;
  white-space: nowrap;
}
.footer {
  letter-spacing: 0 !important;
}

.border-right-link {
  border-right: 1px solid rgba(255, 255, 255, 0.4);
  padding-right: 5px;
  margin-right: 5px;
}
.position-absolute-line {
  position: absolute;
  height: 12px;
  width: 1px;
  background: rgba(255, 255, 255, 0.4);
  bottom: 0px;
}
.gap-link {
  margin-left: 8px;
  margin-right: 8px;
  color: #fcfcfc;
  text-align: center;
  // font-family: "Inter Tight";
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 100%; /* 12px */
  @media (max-width: 768px) {
    font-size: 10px !important;
  }
}
.gap-link-p {
  // margin-left: 8px;
  margin-right: 8px;
  color: #fcfcfc;
  text-align: center;
  // font-family: "Inter Tight";
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 100%; /* 12px */
  @media (max-width: 768px) {
    font-size: 10px !important;
  }
}
.flex-block {
  display: flex;
  @media (max-width: 768px) {
    display: block;
  }
}
.center-right {
  text-align: right;
  color: #fcfcfc;
  // text-align: center;
  // font-family: "Inter Tight";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 12px */
  letter-spacing: 0 !important;
  @media (max-width: 768px) {
    text-align: left;
    color: #fcfcfc;
    // text-align: center;
    // font-family: "Inter Tight";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 10px */
  }
}
.footer {
  letter-spacing: 0 !important;
}
.position-absolutes-color {
  background: rgba(0, 0, 0, 0.08) !important;
}
.max-content-width {
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 104px;
  padding-right: 104px;
  @media (min-height: 716px) and (max-width: 1172px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media (max-width: 716px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.pl-2 {
  padding-left: 14px;
}
/* Sticky column styling */
.sticky-col {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 100px;
  height: calc(
    100vh - 20px
  ); /* Ensure it fits within the viewport minus the offset */
  // overflow-y: scroll;
  padding-right: 15px; /* Space to accommodate the hidden scrollbar */
  letter-spacing: 0 !important;
  @media (max-width: 1050px) {
    display: none !important;
  }
}

/* Hide the scrollbar */
.sticky-col::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  height: 0; /* Remove scrollbar space */
}

.sticky-col {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.sticky-col::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
.sticky-col a li p {
  letter-spacing: 0 !important;
}
.sticky-col-blog {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 10px;
  height: calc(
    100vh - 20px
  ); /* Ensure it fits within the viewport minus the offset */
  // overflow-y: scroll;
  padding-right: 15px; /* Space to accommodate the hidden scrollbar */
  letter-spacing: 0 !important;
  @media (max-width: 1050px) {
    display: none !important;
  }
}

/* Hide the scrollbar */
.sticky-col-blog::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  height: 0; /* Remove scrollbar space */
}

.sticky-col-blog {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.sticky-col-blog::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
.sticky-col-blog a li p {
  letter-spacing: 0 !important;
}
.blog-form-container-case {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 100px; /* Adjust this value to control the top offset */
  z-index: 2; /* Ensure it appears above other content if necessary */
}
/* Blog form container styling */
.blog-form-container {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 20px; /* Adjust this value to control the top offset */
  z-index: 2; /* Ensure it appears above other content if necessary */
}

/* Ensure the sticky column doesn't have a scrollbar */
.sticky-col-case {
  padding-right: 15px; /* Space to accommodate the hidden scrollbar */
  letter-spacing: 0 !important;
  top: 20px;
  @media (max-width: 1050px) {
    display: none !important;
  }
}

/* Hide the scrollbar */
.sticky-col-case::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  height: 0; /* Remove scrollbar space */
}

.sticky-col-case {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.sticky-col-case::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.sticky-col-case a li p {
  letter-spacing: 0 !important;
}

.bg-fa {
  background: #fafafa !important;
}
.blog-text {
  // font-family: "Inter Tight";
  font-size: 39px;
  font-weight: 500;
  line-height: 46.8px;
  text-align: left;
  @media (max-width: 991px) {
    font-size: 30px;
  }
}
.pt-case {
  padding-top: 104px;
  padding-bottom: 104px;
  @media (max-width: 991px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.bg-white-pl {
  @media (max-width: 991px) {
    background: #ffffff;
    padding-left: 14px;
  }
}

.font-family-ie li {
  // font-family: "Inter Tight" !important;
  letter-spacing: 0 !important;
}
.cont-none {
  @media (max-width: 1050px) {
    display: none !important;
  }
}

.cases-more-btn-btn {
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: var(--Round-radi-5xs, 2px);
  border: 1px solid #040c0c;
  background-color: #ffffff;
  color: #040c0c;
  // font-family: "Inter Tight";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  /* 16px */
  margin-top: 40px;

  @media (max-width: 768px) {
    padding: 12px 24px;
    font-size: 14px;
    margin-top: 32px;
  }
}

.cases-more-btn-btn {
  &:hover {
    background-color: #3ebfc1 !important;
    border: 1px solid #efeff0;
  }
}

.welcome-cubet {
  .cubet-connect-header {
    // font-family: "Inter Tight";
    color: #3ebfc1;
    font-size: 35px;
    font-weight: 700;
    line-height: 49px;
    margin-bottom: 16px;

    @media (max-width: 820px) {
      font-size: 23px;
      line-height: 32.2px;
    }
  }

  .cubet-paragraph {
    font-weight: 400;
    font-size: 16px;
    line-height: 22.4px;
    margin-bottom: 60px;
    color: #212529;

    @media (max-width: 820px) {
      font-size: 14px;
      line-height: 19.6px;
      color: #545959;
    }
  }

  .cubet-sub-heading {
    font-weight: 500;
    font-size: 30.2px;
    line-height: 36.24px;
    color: #212529;
    margin-bottom: 16px;

    @media (max-width: 820px) {
      font-weight: 600;
      font-size: 20px;
      line-height: 24.2px;
      color: #00415e;
    }
  }

  .cubet-sub-paragraph {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #212529;

    @media (max-width: 820px) {
      font-size: 15px;
      line-height: 21px;
      color: #545959;
    }
  }

  .mini-heading {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #212529;

    @media (max-width: 820px) {
      font-size: 16px;
    }
  }

  .list {
    // list-style: none;
    // list-style-type: none;
    padding: 0;
    margin-left: 1rem;
    li {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #212529;

      @media (max-width: 820px) {
        font-size: 14px;
        color: #545959;
      }
    }

    @media (max-width: 820px) {
      padding: 0;
    }
  }

  .term-header {
    font-weight: 500;
    font-size: 23.4px;
    line-height: 28px;
    color: #212529;
    @media (max-width: 820px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .center-tab {
    position: sticky;
    left: 0;
    right: 0;
    top: 70px;
    // padding: .5rem;
    border: none;
    background: #ffff;

    @media (min-width: 820px) {
      top: 63px;
    }

    .visible-select {
      visibility: visible;
      display: block;
    }
    .hidden-select {
      visibility: hidden;
      display: none;
    }

    .custom-select {
      width: 100%;
      //     .custom-option {
      //       position: relative;
      //       display: block;
      //       padding: 0 22px 0 22px;
      //       font-size: 13px;
      //       line-height: 18.2px;
      //       font-weight: 400;
      //       color: #3b3b3b;
      //       line-height: 60px;
      //       cursor: pointer;
      //       transition: all 0.5s;
      //       border-bottom: 1px solid #0000001A;
      //  }

      .custom-option.activeOption {
        font-weight: 600;
        font-size: 13px;
        // color: #000000;
        color: #454545;
      }
      .select-wrapper {
        user-select: none;
        width: 100%;
      }

      .select {
        display: flex;
        flex-direction: column;
        border: 1px solid #0000001a;
      }

      .select__trigger {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 17px 16px;
        font-size: 14px;
        font-weight: 400;
        height: 60px;
        line-height: 19.6px;
        color: #080a0e;
        cursor: pointer;
        background: #fbfbfb;
      }
      .custom-options {
        position: absolute;
        // display: block;
        top: 100%;
        // left: 0;
        // right: 0;
        // border: 1px solid #0000001A;
        border-top: 0;
        // transition: all 0.5s;
        // opacity: 0;
        // visibility: hidden;
        // pointer-events: none;
        // z-index: 2;
        border: 1px solid #0000001a;
        padding-left: 16px;
        padding-right: 16px;
        width: 100%;
        background: #fbfbfb;
        border-top: none;
      }

      .custom-option {
        position: relative;
        display: block;
        padding: 16px 16px 16px 0px;
        font-size: 13px;
        line-height: 18.2px;
        font-weight: 400;
        color: #696969;
        cursor: pointer;
        transition: all 0.5s;
        border-bottom: 1px solid #0000001a;
      }

      .custom-option:last-child {
        border-bottom: none;
      }

      .custom-option:hover {
        cursor: pointer;
        background-color: #b2b2b2;
      }
      .custom-option.selected {
        color: #ffffff;
        background-color: #305c91;
      }

      .arrow {
        position: relative;
        height: 15px;
        width: 15px;
      }
      .arrow::before,
      .arrow::after {
        content: "";
        position: absolute;
        bottom: 0px;
        width: 0.15rem;
        height: 100%;
        transition: all 0.5s;
      }
      .arrow::before {
        left: -5px;
        transform: rotate(-45deg);
        background-color: #394a6d;
      }
      .arrow::after {
        left: 5px;
        transform: rotate(45deg);
        background-color: #394a6d;
      }
      .open.arrow::before {
        left: -5px;
        transform: rotate(45deg);
      }
      .open.arrow::after {
        left: 5px;
        transform: rotate(-45deg);
      }
    }

    @media (min-width: 952px) {
      display: none;
    }
  }
  .right-tab {
    position: sticky;
    left: 0;
    width: calc(25% - 30px);
    top: 100px;
    background: transparent;
    padding: 0.5rem;
    border: none;
    box-shadow: unset !important;
    border-radius: 25px;
    margin-bottom: 0;
    display: block;
    height: 50vh;
    ul {
      list-style: none;
      list-style-type: none;
    }

    a {
      text-decoration: none;
      color: #000000;
      font-weight: 500;
      font-size: 16px;
      line-height: 28.96px;
    }

    button {
      all: unset;
      cursor: pointer;
      // font-family: "Inter Tight";
      margin-left: 16px;
      font-weight: 500;
      font-size: 16px;
      line-height: 28.96px;
      color: #696969;
    }

    .tabWrapper {
      border-left: 3px solid transparent;
    }

    .activeDiv {
      border-left: 3px solid #00415e;
    }

    .activeTabConnect {
      font-weight: 500;
      font-size: 16px;
      line-height: 28.96px;
      color: #000000;
    }
  }
}
